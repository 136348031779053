.header {
  .navbar {
    box-shadow: 0px 0px 12px -6px var(--color-royal-blue);
    .header-dropdown {
      @include header-btn2;
      padding: 0rem 1rem !important;
      background-color: #6475f12e;
      .nav-link {
        font-size: 14px;
        color: var(--color-royal-blue);
      }
    }
    .loginbtn {
      @include login-sigup-btn;
      border-radius: 20px 0px 0px 20px;
    }
    .signbtn {
      @include login-sigup-btn;
      border-radius: 0px 20px 20px 0px;
    }
    .abtn-primary-custom {
      @include header-btn2;
      background-color: var(--color-river-bed);
      position: relative;
      line-height: 21px;
      border: 1px solid var(--color-river-bed);
      &:hover,
      &:focus,
      &:active,
      &.active {
        border: 1px solid var(--color-river-bed);
      }
      &::before {
        position: absolute;
        right: 21px;
        top: -10px;
        content: "free";
        height: 17px;
        width: 67px;
        background-color: var(--color-my-sin);
        border-radius: 20px;
        color: var(--color-black);
        font-family: var(--font-family-open);
        font-size: 11px;
        line-height: 17px;
        font-weight: 700;
        vertical-align: middle;
        text-transform: uppercase;
      }
    }
    .abtn-primary-custom1 {
      @include header-btn2;
      line-height: 21px;
      background-color: var(--color-royal-blue);
    }
  }
}
.header-divider {
  padding: 36px;
}
footer {
  .nav {
    .nav-link {
      color: var(--color-white);
      font-size: 14px;
      font-weight: 400;
      padding-left: 0;
      padding-right: 0;
      text-transform: capitalize;
      &:hover,
      &:focus,
      &:active,
      &.active {
        color: var(--color-royal-blue);
      }
    }
    .nav-link.no-hover {
      color: var(--color-white);
    }
  }
}

// Sign Up Page

.signiup-slider {
  background-image: url(../assets/img/bgsignup.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .apply-btn {
    @include btn-custom;
    background-color: var(--color-blue-ribbon);
    border-color: var(--color-blue-ribbon);
    color: var(--color-white);
    font-size: 14px;
    font-weight: 700;
    border-radius: 30px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    &:hover,
    &:focus,
    &:active {
      background-color: var(--color-white);
      color: var(--color-royal-blue);
    }
  }
  .carousel {
    .carousel-indicators [data-bs-target] {
      width: 10px;
      height: 10px;
      background-color: var(--color-titan-white);
      background-clip: padding-box;
      border: 3px solid var(--color-royal-blue);
      opacity: 1;
      transition: all 0.3s ease-in-out;
      &.active {
        background-color: var(--color-royal-blue);
      }
    }
    .carousel-indicators {
      bottom: -6%;
      button {
        height: 10px;
        width: 10px;
        background-color: transparent;
        border-radius: 50%;
        border-color: var(--color-royal-blue);
      }
    }
  }
}

.signup-form {
  .sign-input {
    border-color: var(--color-iron);
    border-radius: 0;
    font-size: 16px;
    font-weight: 700;
    font-family: var(--font-family-open);
    &:focus,
    &:hover {
      border-color: var(--color-blue-ribbon);
    }
  }
}

.user-dash-siedebar {
  background-color: var(--color-white);
  width: 100%;
  .nav-link {
    color: var(--color-tundora);
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    display: block;
    padding: 10px 20px 10px 15px;
    line-height: 30px;
    text-transform: capitalize;
    border-radius: 0.2rem;
    position: relative;
    &::after {
      content: " ";
      height: 10px;
      width: 10px;
      position: absolute;
      right: 7px;
      top: 20px;
      background-image: url(../assets/img/arrow-sidebarb.png);
      background-repeat: no-repeat;
    }
    &:active,
    &:focus,
    &:hover,
    &.active {
      color: var(--color-royal-blue);
      background-color: var(--color-titan-white);
      &::after {
        background-image: url(../assets/img/arrow-sidebarc.png);
      }
    }
  }
}
.breadcrumb-topbar {
  .breadcrumb-item {
    color: var(--color-tundora);
    a {
      color: var(--color-tundora);
      text-decoration: none;
      text-transform: capitalize;
    }
    &:hover,
    &.active,
    &:focus {
      color: var(--color-blue-ribbon);
      a {
        color: var(--color-blue-ribbon);
      }
    }
  }
}
// ---------------------------------------------

@mixin transitionAll() {
  transition: all 0.3s ease-out;
}

.myreal-container {
  display: flex;
  width: 100%;
  height: 100%;
}
.sidebar {
  flex: 1 0 280px;
  padding: 0.75rem;
  background-color: var(--color-white);
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  width: 280px;
  @include transitionAll();
  &.is-collapsed {
    transform: translateX(-100%);
  }
}
.main-content {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  @include transitionAll();
  margin-left: 280px;
  &.is-full-width {
    margin-left: 280px;
  }
}
@include media-breakpoint-down(lg) {
  .main-content {
    margin-left: 0;
    &.is-full-width {
      margin-left: 0;
    }
  }
}
.myreal-container-close {
  display: flex;
  width: 100%;
  height: 100%;
  .sidebar {
    transform: translateX(-100%);
  }
  .main-content {
    margin-left: 0;
  }
}

// ============================== FILE UPLOAD SECTION ================================================

.file-upload-section {
  .textboxar {
    width: 100%;
    margin-left: -20px;
  }
  .file-container {
    font-size: 13pt;
    color: #4d4d4d;
    position: relative;
  }
  // .file-container .file-overlay {
  //   position: fixed;
  //   width: 100vw;
  //   height: 100vh;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   z-index: 10;
  // }
  .file-container .file-wrapper {
    display: block;

    height: 80vh;
    max-height: 500px;
    min-height: 400px;
    min-width: 400px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 30px auto;
    background-color: var(--dialog-color);
    z-index: 20;
    border-radius: var(--dialog-border-radius);
    padding: var(--dialog-padding);
    box-shadow: 3px 3px 15px 0px rgb(242, 242, 242);
  }
  .file-container .file-wrapper .file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    z-index: 10;
    cursor: pointer;
    opacity: 0;
  }
  .file-container .file-wrapper .file-input--active + .file-content {
    background: var(--drag-over-background);
  }
  .file-container .file-wrapper .file-input--active + .file-content .file-icon {
    opacity: 0.5;
  }
  .file-container
    .file-wrapper
    .file-input--active
    + .file-content
    .file-icon
    i {
    animation-name: bounce;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }
  .file-container
    .file-wrapper
    .file-input--active
    + .file-content
    .file-icon
    .icon-shadow {
    animation-name: shrink;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
  }
  .file-container .file-wrapper .file-content {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: var(--dialog-border-radius);
    transition: 0.2s;
  }
  .file-container .file-wrapper .file-content .file-infos {
    position: absolute;
    display: flex;
    width: 80%;
    height: 50%;
    min-height: 202px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
  .file-container .file-wrapper .file-content .file-infos .file-icon {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    color: var(--icon-color);
    background-color: #dcf9ff21;
    border-radius: var(--dialog-border-radius);
    padding: var(--dialog-padding);
    transition: 0.2s;
    border: dashed 2px #91c7d263;
  }
  .file-container .file-wrapper .file-content .file-infos .file-icon i {
    margin: 20px 0;
    width: 100%;
    color: #9a94ef;
  }
  .file-container
    .file-wrapper
    .file-content
    .file-infos
    .file-icon
    .icon-shadow {
    position: relative;
    display: block;
    width: 95px;
    height: 7px;
    border-radius: 100%;
    background-color: var(--drag-over-background);
    top: -17px;
    margin-left: auto;
    margin-right: auto;
  }
  .file-container .file-wrapper .file-content .file-infos .file-icon > span {
    position: absolute;
    bottom: var(--dialog-padding);
    width: calc(100% - var(--dialog-padding) * 2);
  }
  .file-container
    .file-wrapper
    .file-content
    .file-infos
    .file-icon
    > span
    span {
    display: none;
  }
  .file-container
    .file-wrapper
    .file-content
    .file-infos
    .file-icon
    > span
    .has-drag {
    display: inline;
  }
  .file-container .file-wrapper .file-content .file-infos .file-icon i,
  .file-container .file-wrapper .file-content .file-infos .file-icon span {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
  .file-container .file-wrapper .file-content .file-name {
    position: absolute;
    width: 100%;
    text-align: middle;
    left: 0;
    bottom: var(--dialog-padding);
    right: 0;
    font-weight: bold;
    font-size: 15pt;
    margin: auto;
    text-align: center;
  }
  @keyframes bounce {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes shrink {
    0% {
      width: 95px;
    }
    50% {
      width: 75px;
    }
    100% {
      width: 95px;
    }
  }

  .upload-text {
    //   position: absolute;
    margin-top: 0px;
    display: flex;
    flex-flow: column;
    align-items: center;
    left: calc(50% - 59px);
    font-size: 10px;
    color: #b7b7b7;
  }

  .bold {
    font-weight: 500;
    margin: 5px 0;
    font-size: 12px;
    color: grey;
  }

  .upload-text button,
  input[type="button"] {
    padding: 8px 12px;
    border: 0;
    color: white;
    border-radius: 7px;
    background: #9a94ef;
    font-size: 12px;
  }

  input[type="button"] {
    background: #9a94ef;
    width: 100%;
    padding: 12px;
    min-width: 400px;
    width: 30vw;
  }

  textarea {
    // width: 100%;
    // min-width: 400px;
    // width: 30vw;
    margin: 0;
    border-radius: 20px;
    padding: 10px 10px 0 10px;
    font-family: "Rubik", sans-serif;
    color: grey;
  }
}
