@import "layout";

body {
  font-family: var(--font-family-rubic);
  font-size: var(--font-size-base);
  background-color: var(--color-bgwhite-mode);
}

.main-hero-slider {
  .carousel {
    .carousel-caption {
      position: absolute;
      right: 15%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #fff;
      text-align: center;
      top: 0;
      bottom: 0;
      padding: 0;
    }
    .carousel-control-prev,
    .carousel-control-next {
      width: 2%;
      opacity: 1;
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 5rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        background: rgba(100, 100, 100, 0.581);
        background-repeat: no-repeat;
        background-position: 6px 30px;
        &:hover,
        &:focus {
          background: rgba(23, 94, 225, 0.581);
        }
      }
      .carousel-control-prev-icon {
        background-image: url(../assets/img/left-arrow.png);
        background-repeat: no-repeat;
      }
      .carousel-control-next-icon {
        background-position: 8px 30px;
        background-image: url(../assets/img/right-arrow.png);
        background-repeat: no-repeat;
      }
    }
  }
}
.filters {
  position: relative;
  .fill-tab-sec {
    .nav-tabs {
      .nav-link {
        background-color: var(--color-white);
        color: var(--color-black);
        border-radius: 0.4rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        margin-right: 0.5rem;
        &:hover,
        &.active,
        &.show {
          background-color: var(--color-zircon);
          border-color: var(--color-royal-blue);
          color: var(--color-royal-blue);
          font-weight: 600;
        }
      }
    }
  }
}

.recomend-card {
  border-radius: 20px 20px 15px 15px;
}

.event-slider-sec {
  background-image: url(../assets/img/background1.png);
}
.finance-card {
  background-color: var(--color-white);
  .financeimg-box {
    background-color: var(--color-dusty-gary);
    height: 100px;
    width: 100px;
    border-radius: 50%;
    text-align: center;
    padding: 25px;
  }
  .card-title {
    font-size: 17px;
    font-weight: 500;
    color: var(--color-black);
    font-family: var(--font-family-rubic);
    margin-top: 1.5rem;
  }
  .card-text {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-black);
    font-family: var(--font-family-rubic);
    margin-bottom: 1.5rem;
  }
  .btn-dark {
    font-family: var(--font-family-rubic);
    background-color: var(--color-black);
    color: var(--color-white);
    font-size: 13px;
    font-weight: 500;
    border-radius: 0.4rem;
    text-transform: uppercase;
    &:focus,
    &:hover {
      background-color: var(--color-white);
      color: var(--color-royal-blue);
    }
  }
  &:focus,
  &:hover {
    background-color: var(--color-royal-blue);
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    .financeimg-box {
      background-color: #3d52f7;
    }
    .card-title,
    .card-text {
      color: var(--color-white);
    }
  }
}

.our-agent-card {
  .nav {
    margin-top: 1.75rem;
    .nav-link {
      border: 1px solid var(--color-concrete);
      color: var(--color-code-gray3);
      &:hover,
      &:focus,
      &.active,
      &:active {
        color: var(--color-royal-blue);
      }
    }
  }
}
.enqury-sec {
  background-color: var(--color-Sazerac);
  border-top: 1px solid var(--color-tuft-bush);
  border-bottom: 1px solid var(--color-tuft-bush);
}

.bgside {
  background-color: rgb(207, 207, 207);
}
.signiup-slider {
  .carousel-caption {
    position: static;
  }
}

.event-tabs {
  .nav-tabs {
    border: 0;
    .nav-link {
      border: 0;
      font-size: 18px;
      color: var(--color-dove-gray);
      font-weight: 500;
      position: relative;
      &::before {
        position: absolute;
        content: " ";
        bottom: 0;
        left: 41%;
        height: 3px;
        width: 30px;
        border-radius: 20px;
        background-color: transparent;
      }
      &.active {
        color: var(--bs-nav-tabs-link-active-color);
        border: 0;
        color: var(--color-royal-blue);
        &::before {
          background-color: var(--color-royal-blue);
        }
      }
    }
    &.show .nav-link {
      color: var(--bs-nav-tabs-link-active-color);
      background-color: var(--bs-nav-tabs-link-active-bg);
      border-color: var(--bs-nav-tabs-link-active-border-color);
    }
  }
}

.about-mission {
  background-image: url(../assets/img/about-mission.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.about-vision {
  background-image: url(../assets/img/about-vision.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center right;
}

.about-building {
  background-image: url(../assets/img/about-bg-build.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  .building-img {
    margin-top: -4rem;
  }
  @media (max-width: 768.98px) {
    .building-img {
      margin-top: 1rem;
    }
  }
}
